import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NextComponentType, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import NextNprogress from 'nextjs-progressbar';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Box, useMediaQuery } from '@chakra-ui/react';

import '@babel/polyfill';
import 'swiper/swiper.min.css';
import '@/src/components/UI/HouseDetail/slider.css';
import 'tailwindcss/tailwind.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '../styles/globals.css';
import '../styles/markdown.css';

import WindowContext from '@/src/context/WindowContext';
import { FavoriteProvider } from '@/src/context/MyFavoriteContext';

import ErrorFallback from '@/src/components/UI/ErrorFallback';
//@ts-ignore
import Toast from '@/src/components/UI/Toast';
import Theme from '@/src/components/Theme';
import Footer from '@/src/components/UI/Footer';
import { LayoutContextProvider } from '@/src/context/LayoutContext';
import SpeedInsights from '@/src/components/common/SpeedInsights';
import { FaPhoneAlt } from 'react-icons/fa';
import classNames from 'classnames';
import useShowMobileFooter from '@/src/hooks/useShowMobileFooter';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: process.env.NODE_ENV === 'production',
    // @ts-ignore
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const LISTING_WIDTH = ['480px', '512px'];

type IComponent = NextComponentType<NextPageContext, any> & {
  isBackyard?: boolean;
};

interface IAppProps extends AppProps {
  Component: IComponent;
}

function MyApp({ Component, pageProps: { ...pageProps } }: IAppProps) {
  const windowCtx = useRef({ scrollY: 0 });
  const boxRef = useRef<HTMLDivElement>(null);
  const [onScrollCallBacks, setOnScrollCallBacks] = useState<Map<string, Function>>(new Map());
  const { asPath } = useRouter();
  const matches = useMediaQuery(['(min-width: 1024px)', '(min-width: 768px)']);
  const index = matches.findIndex(Boolean);
  const imageSizes = LISTING_WIDTH[index] || '768px';
  useEffect(() => {
    if (boxRef.current?.scrollTo) {
      boxRef.current?.scrollTo({ left: 0, top: 0 });
    }
  }, [asPath]);
  const handleScroll = useCallback(
    (e: any) => {
      windowCtx.current.scrollY = e.target.scrollTop;
      [...onScrollCallBacks.keys()].forEach((name) => {
        onScrollCallBacks.get(name)?.call(null);
      });
    },
    [onScrollCallBacks]
  );

  return (
    <Theme>
      <PhoneCallFAB />
      <SpeedInsights />
      <NextNprogress color='black' />
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <Box
          ref={boxRef}
          position={'relative'}
          onScroll={handleScroll}
          h='100vh'
          maxH='100vh'
          overflowY='scroll'
          // className={'scroll-pt-15 sm:scroll-pt-[4rem]'}
        >
          <LayoutContextProvider>
            <WindowContext.Provider
              value={{
                scrollY: windowCtx.current.scrollY,
                onScrollCallBacks,
                setOnScrollCallBacks,
                windowRef: boxRef,
                imageSizes,
              }}
            >
              <FavoriteProvider>
                <Component {...pageProps} />
              </FavoriteProvider>
              <Footer />
              <Toast />
            </WindowContext.Provider>
          </LayoutContextProvider>
        </Box>
      </Sentry.ErrorBoundary>
    </Theme>
  );
}

// noinspection JSUnusedGlobalSymbols
export default appWithTranslation(MyApp);

const PhoneCallFAB = () => {
  const showFooter = useShowMobileFooter();

  return (
    <a
      id={'phone-call-fab'}
      href={`tel:${process.env.NEXT_PUBLIC_FUNWOO_TAIWAN_CELLPHONE}`}
      className={classNames(
        'fixed right-4 z-50 flex items-center justify-center',
        {
          'bottom-16': showFooter,
          'bottom-12': !showFooter,
        },
        'w-12 h-12',
        'bg-gray900 text-white',
        'rounded-[999px] shadow-itemCard',
        'hoverable:hover:shadow-itemCardFocus transition-[shadow,transform] duration-300',
        'py-2 px-3'
      )}
    >
      <FaPhoneAlt />
    </a>
  );
};
