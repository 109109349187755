import { ReactElement } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Flex, Icon } from '@chakra-ui/react';
import { getYear } from 'date-fns';
import { FaInstagram, FaLine, FaYoutube } from 'react-icons/fa';
import { TiSocialFacebook } from 'react-icons/ti';
import useT from '@/lib/t';
import { LineEventTypes, sendLineTagEvent } from '@/utils/sendLineTagEvent';

type LinkProps = {
  href: string;
  label: string;
};
const TermsData = [
  {
    key: 'tos',
    href: '/terms-of-service',
  },
  {
    key: 'privacy',
    href: '/privacy-policy',
  },
  {
    key: 'cookies',
    href: '/cookies',
  },
  {
    key: 'license',
    href: '/real-estate-brokerage-license',
  },
];
const TermItem = ({ title, href, showLine = true }: { title: string; href: string; showLine: boolean }) => {
  return (
    <>
      <Link href={href}>
        <a className='text-xs leading-normal tracking-wide font-medium  text-white mx-4 cursor-pointer'>{title}</a>
      </Link>
      {showLine ? <div className={' h-m w-px bg-white'} /> : null}
    </>
  );
};

function LinkText({ href, label }: LinkProps): ReactElement {
  return (
    <Link href={href}>
      <a>
        <p className='text-sm leading-normal uppercase text-white mb-2'>{label}</p>
      </a>
    </Link>
  );
}

function Caption({ label }: { label: string }): ReactElement {
  return (
    <>
      <p className='text-sm  text-gray300 leading-normal tracking-wide font-medium uppercase'>{label}</p>
      <div className={' w-full h-px bg-gray300 mt-2 mb-4 '} />
    </>
  );
}

function SocialIcon({
  href,
  alt,
  icon,
  lineTagEventType,
}: {
  href?: string;
  alt: string;
  icon: any;
  lineTagEventType: LineEventTypes;
}): ReactElement {
  return (
    <a
      onClick={() => sendLineTagEvent(lineTagEventType)}
      href={href || ''}
      target='_blank'
      rel='noopener noreferrer'
      aria-label={alt}
      className={'flex items-center justify-center mr-4 w-12 h-12 border border-white rounded-[50%]'}
    >
      <Icon color='white' w={6} h={6} as={icon} />
    </a>
  );
}

export default function Footer() {
  const router = useRouter();
  const t = useT('share.header');
  if (router.asPath.startsWith('/line')) return null;
  return (
    <footer>
      <div className={'w-full flex justify-center items-center bg-gray900 flex-col'}>
        <div className={'flex flex-col md:flex-row w-full max-w-screen-lg pt-8 pb-5 justify-between bg-gray900 px-8'}>
          <div className={'flex mb-6 md:mb-0 md:mr-6 w-full md:flex-[304px]'}>
            <div className={'flex flex-col flex-1 mr-6'}>
              <Caption label='客戶服務' />
              <LinkText href='/buy' label='買屋' />
              <LinkText href='/sell' label='賣屋' />
              <LinkText href='/abroad ' label='美國置產' />
              <LinkText href='/building ' label='全新建案' />
            </div>
            <div className={'flex flex-col flex-1'}>
              <Caption label='關於' />
              <LinkText href='/about' label='公司簡介' />
              {/*<LinkText href='/agent' label='房產顧問' />*/}
              <LinkText href='/media' label='媒體採訪' />
              {/*<LinkText href='/jobs' label='工作機會' />*/}
            </div>
          </div>
          <div className={'flex w-full md:flex-[632px]'}>
            <div className={'flex flex-col flex-1 mr-6'}>
              <Caption label='聯絡我們' />
              <a
                rel={'noopener noreferrer'}
                target={'_blank'}
                href={'mailto:info@funwoo.com.tw'}
                className='text-sm leading-normal text-white mb-2'
                onClick={() => sendLineTagEvent(LineEventTypes.send_mail)}
              >
                電子信箱：info@funwoo.com.tw
              </a>
              <a
                rel={'noopener noreferrer'}
                target={'_blank'}
                href={`tel:${process.env.NEXT_PUBLIC_FUNWOO_TAIWAN_CELLPHONE}`}
                className='text-sm leading-normal uppercase text-white mb-2'
                onClick={() => sendLineTagEvent(LineEventTypes.phone_call)}
              >
                {`客服電話：${process.env.NEXT_PUBLIC_FUNWOO_TAIWAN_CELLPHONE}`}
              </a>
              <Flex>
                <SocialIcon
                  href={process.env.NEXT_PUBLIC_FACEBOOK_PAGE_URL}
                  icon={TiSocialFacebook}
                  alt='find FUNWOO on facebook'
                  lineTagEventType={LineEventTypes.visit_fb}
                />
                <SocialIcon
                  href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}
                  icon={FaInstagram}
                  alt='find FUNWOO on instagram'
                  lineTagEventType={LineEventTypes.visit_ig}
                />
                <SocialIcon
                  href={process.env.NEXT_PUBLIC_LINE_OA_URL}
                  icon={FaLine}
                  alt='find FUNWOO on LINE'
                  lineTagEventType={LineEventTypes.visit_line_bot_oa}
                />
                <SocialIcon
                  href={process.env.NEXT_PUBLIC_YOUTUBE_URL}
                  icon={FaYoutube}
                  alt='find FUNWOO on youtube'
                  lineTagEventType={LineEventTypes.visit_yt}
                />
              </Flex>
            </div>
            <div className={'flex flex-col flex-1'}>
              <p className='text-sm  text-gray300 leading-normal tracking-wide font-medium uppercase'>服務據點</p>
              <div className={' w-full h-px bg-gray300 mt-2 mb-4 '} />
              <div className={'text-sm leading-normal text-white'}>
                <ul>
                  {/*<li className={'flex items-center'}>*/}
                  {/*  <span className={'flex items-center justify-center w-4'}>*/}
                  {/*    <span className={'block w-1 h-1 bg-white rounded-[999px]'} />*/}
                  {/*  </span>*/}
                  {/*  台北市信義區松仁路100號34樓*/}
                  {/*</li>*/}
                  <li className={'flex items-center mb-2'}>
                    <span className={'flex items-center justify-center w-4'}>
                      <span className={'block w-1 h-1 bg-white rounded-[999px]'} />
                    </span>
                    台北市信義區忠孝東路四段563號11樓
                  </li>
                  <li className={'flex items-center'}>
                    <span className={'flex items-center justify-center w-4'}>
                      <span className={'block w-1 h-1 bg-white rounded-[999px]'} />
                    </span>
                    台中市西區館前路57號1樓
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={' h-px w-full bg-white'} />
        <div className={'flex max-w-screen-lg justify-center items-center py-4 flex-row'}>
          <p className='text-xs leading-normal tracking-wide font-medium uppercase text-white'>
            © {getYear(Date.now())} 德載國際不動產 FUNWOO
          </p>
          {TermsData.map((item, idx) => {
            return <TermItem showLine={idx !== 3} title={t(item.key)} href={item.href} key={item.href} />;
          })}
        </div>
      </div>
    </footer>
  );
}
