import React, { createContext, useContext, useState } from 'react';
import { NOOP } from '@/constants/noop';

interface LayoutContextState {
  headerHeight: number;
  setHeaderHeight: (headerHeight: number) => void;
}

const LayoutContext = createContext<LayoutContextState>({ headerHeight: 0, setHeaderHeight: NOOP })

export const LayoutContextProvider: React.FC = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  return <LayoutContext.Provider value={{ headerHeight, setHeaderHeight }}>{children}</LayoutContext.Provider>
}

export const useLayoutContext = () => useContext(LayoutContext)
