import React from 'react';

import { SpeedInsights as VercelSpeedInsights } from '@vercel/speed-insights/react';
import { useRouter } from 'next/router';

const SpeedInsights: React.FC = () => {
  const router = useRouter();

  return <VercelSpeedInsights route={router.pathname} />;
};

export default SpeedInsights;
