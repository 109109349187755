import { createContext, useEffect, useCallback, useState, ReactNode, ReactElement } from 'react';

type FavoriteContextType = {
  sids: string[];
  add: (sid: string) => void;
  remove: (sid: string) => void;
};
const MyFavoriteContext = createContext<FavoriteContextType>({
  sids: [],
  add: () => {
    /* nada */
  },
  remove: () => {
    /* nada */
  },
});

type FavoriteType = Set<string>;

export function FavoriteProvider({ children }: { children: ReactNode }): ReactElement {
  const [favorites, setFavorites] = useState<FavoriteType>(new Set());
  useEffect(() => {
    (async () => {
      try {
        if (localStorage.getItem('favorites') !== null) {
          const sids: string[] = JSON.parse(localStorage.getItem('favorites') || '[]');
          setFavorites(new Set(sids));
        }
      } catch (ex) {
        // nada
      }
    })();
  }, []);

  const persist = useCallback((data: FavoriteType): void => {
    setFavorites(new Set(data));
    try {
      localStorage.setItem('favorites', JSON.stringify(Array.from(data)));
    } catch (ex) {
      // nada
    }
  }, []);

  const add = useCallback(
    (sid: string): void => {
      favorites.add(sid);
      persist(favorites);
    },
    [favorites]
  );

  const remove = useCallback(
    (sid: string): void => {
      favorites.delete(sid);
      persist(favorites);
    },
    [favorites]
  );

  return (
    <MyFavoriteContext.Provider value={{ sids: Array.from(favorites), add, remove }}>
      {children}
    </MyFavoriteContext.Provider>
  );
}

export default MyFavoriteContext;
