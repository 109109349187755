export enum LineEventTypes {
  'phone_call' = 'phone_call',
  'add_line' = 'add_line',
  'send_mail' = 'send_mail',
  'visit_fb' = 'visit_fb',
  'visit_ig' = 'visit_ig',
  'visit_line_bot_oa' = 'visit_line_bot_oa',
  'visit_yt' = 'visit_yt',
  'visit_line' = 'visit_line',
  'download_app_ios' = 'download_app_ios',
  'download_app_android' = 'download_app_android',
}

export const sendLineTagEvent = (type: LineEventTypes | string) => {
  window?._lt(
    'send',
    'cv',
    {
      type,
    },
    [process.env.NEXT_PUBLIC_LINE_TAG_ID!]
  );
};
