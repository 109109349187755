export default function ErrorFallback({ error, resetError }: { error: Error, resetError: () => void }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetError}>
        Try again
      </button>
    </div>
  );
}
