import { useContext } from 'react';
import React from 'react';
const WindowContext = React.createContext(
  {} as {
    scrollY: number;
    onScrollCallBacks: Map<string, Function>;
    setOnScrollCallBacks: React.Dispatch<React.SetStateAction<Map<string, Function>>>;
    windowRef: React.RefObject<HTMLDivElement>;
    imageSizes: string;
  }
);

const useWindowContext = () => useContext(WindowContext);
export default WindowContext;
export { useWindowContext };
