import { ToastContainer, Slide } from 'react-toastify';
import Image from 'next/image';

export { toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';

function CloseIcon() {
  return <Image width={24} height={24} src={'/toast-close.svg'} className={'w-6 h-6 cursor-pointer'} />;
}

export default function ToastImpl(props: any) {
  return (
    <ToastContainer
      className="funwoo-toast"
      position="bottom-center"
      autoClose={3000}
      closeButton={CloseIcon}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick={true}
      rtl={false}
      pauseOnVisibilityChange={true}
      draggable={false}
      pauseOnHover={true}
      transition={Slide}
      {...props}
    />
  );
}
